<template>
	<div class="enterprise">
			<div class="card_cont">
				<div class="top_info" style="border: none;margin-top: 0px;">
					<div class="title_name">温馨提示：</div>
				</div>
				<p class="cq_text_tips">
					以下设置将影响员工信息表和入职登记表的打印效果，其中入职登记表不受页脚样式打印设置影响。
				</p>
				<div v-for="item in mailInfo_list">
					<div class="top_info">
						<div class="title_name">{{item.name}}</div>
					</div>
					<div>
						<p class="cq_text_tips">
							{{item.describe}}
						</p>
						<div class="infoBox">
							<!--  -->
							<div style="display: flex;" v-if="item.type=='header_style'">
							<a-radio-group  @change='set_info(item)' v-model:value="item.value.type">
							<div class="card_item">
								<div class="sub">
									<div class="box">
										
										<div class="headerContent">
											<span>
												<img class="compamg_logo" :src="item.option.company_remark" >
											</span>
											<span style="font-weight: bold;">
											{{item.option.header_style.company_name}}</span>
										</div>
										<div class="con">
											内容区域
										</div>
									</div>
								</div>
								<div class="main" >
									<a-radio value="need"> 打印页眉</a-radio><span  @click="go_edit" class="cq_color">编辑页眉</span>
								</div>
							</div>
							
							<div class="card_item">
								<div class="sub">
									<div class="box">
										
										
										<div class="con">
											内容区域
										</div>
									</div>
								</div>
								<div class="main">
									<a-radio value="unneed"> 不打印页眉</a-radio><span></span>
								</div>
							</div>
							</a-radio-group>
							</div>
							

							
							<div v-if="item.type=='footer_style'">
								
									<p class="item_p" >
										<a-checkbox class="mar8"  @change='set_info(item)' v-model:checked="item.value.is_true"></a-checkbox>
										<span class="mar8">表单底部显示打印时间、打印人</span>
									</p>
							</div>

							
							<div v-if="item.type=='form_style'">
								<a-radio-group  @change='set_info(item)' v-model:value="item.value.type">
									<p class="item_p">
										<a-radio value="nimble"> </a-radio>
										<span> 灵活表格排版，有多少记录就打印多少行，没有记录则不打印  </span>
									</p>
									<p class="item_p">
										<a-radio value="unify"> </a-radio>
										<span class="mar8">表单底部显示打印时间、打印人</span>
										
									</p>
								
								</a-radio-group>
							</div>
							
							
							<div v-if="item.type=='approve_style'">
								<a-radio-group  @change='set_info(item)' v-model:value="item.value.type">
								<div class="card_item card_item2"  v-for="op in item.option">
									<div class="sub">
										<div class="box" >
											<div class="con">
												
												<img v-if="op.image" class="tmpe_img" :src="op.image.split('?')[0]" >
											</div>
										</div>
									</div>
									<div class="main">
										<a-radio :value="op.type"> {{op.name}}</a-radio><span></span>
									</div>
								</div>
								</a-radio-group>
								
							</div>
							
							<div v-if="item.type=='license_style'">
								<a-radio-group  @change='set_info(item)' v-model:value="item.value.type">
								<div class="card_item card_item2"  v-for="op in item.option">
									<div class="sub">
										<div class="box" >
											<div class="con">
												<img v-if="op.image" class="tmpe_img" :src="op.image.split('?')[0]" >
											</div>
										</div>
									</div>
									<div class="main">
										<a-radio :value="op.type"> {{op.name}}</a-radio><span></span>
									</div>
								</div>
								</a-radio-group>
								
							</div>
						</div>
					</div>
				</div>

			</div>
	</div>
</template>

<script>
	import {
		getPersonnelForm,savePersonnelForm
	} from '@/api/enterprise.js'
	
	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';

	export default {
		components: {
		
		},
		data() {
			return {
				txDay:[],
				years: [],
				retire: [],
				againMonth:[],
				select_list2: [],
				ViewRangeType: '1',
				ViewBookEmployeestype: '1',
				ViewRangeTypeVisit: false,
				ViewBookEmployeesVist: false,
				departmentDataFilter: [],
				labelColEdit: {
					span: 2
				},

				wrapperCol: {
					span: 12
				},

				scaleType: [],
				select_list: [],
				write_off_reason: [],
				chooseList: [],
				mailInfo_list: [
					[],
					[],
					[]
				],
			}

		},
		created() {
			this.get_info();
			
		},
		methods: {
			go_edit(){
				this.$router.push({path:'/PersonnelTableStyle/header'})
			},
			set_info(item){
				savePersonnelForm({data:item}).then(res=>{
					message.success('操作成功');
				})
			},
			get_info() {
				getPersonnelForm({
					data: {}
				}).then(res => {
					this.mailInfo_list = res.data.list
				})
			},

			handle_submit() {

			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.img_code {
		height: 30px;
		width: 70px;
		margin-left: 12px;
		margin-right: 12px;
	}

	.text_code {
		height: 30px;
		width: 98px;
		margin-left: 12px;
		// margin-right: 12px;
	}

	.text_code2 {
		width: 130px;
	}

	.code_p {
		display: flex;
		margin-bottom: 12px;
	}

	.info {
		line-height: 44px;

	}

	.mailInfoEdit {
		padding: 32px;
		margin-top: 12px;
		background-color: #f8f8f9;
		border: 1px dashed #e1e6eb;
		border-radius: 4px;
	}

	.chooseItem {
		border: 1px solid @cq_text_c6;
		color: @cq_text_c6;
		margin-right: 12px;
		margin-bottom: 12px;
		text-align: center;
		border-radius: 6px;
		position: relative;
		line-height: 22px;
		padding: 5px 15px 5px 10px;

	}

	.chooseItemC {
		border-color: @cq_color;
		color: @cq_color;

	}

	.chooseItemactieve {
		position: absolute;
		right: -1px;
		height: 24px;
		width: 24px;
		bottom: -1px;

	}

	.infoBox {
		margin-top: 20px;

		.item_p {
			line-height: 32px;
			margin-bottom: 10px;
		}

		.mar8 {
			margin-right: 8px;
		}

		.mar_30 {
			margin-top: 30px;
		}
	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;
	
		span:nth-child(1) {
			flex: 1;
			margin-left: 8px;
		}
	
		span:nth-child(2) {
			flex: 3;
		}
	
		span:nth-child(3) {
			flex: 2;
			text-align:center;
	
		}
		
	}
	.w100{
		width: 100px;
	}
	.card_item{
		    position: relative;
		    display: inline-block;
		    width: 250px;
		    margin-right: 24px;
		    padding-bottom: 32px;
		    vertical-align: middle;
		    border: 1px solid #e1e6eb;
		    border-radius: 4px;
		    cursor: pointer;
	}
	
	.tmpe_img{
		background: #fff;width: 100%;height: 100%;
	}
	.sub{
		padding: 8px;
		    border-bottom: 1px solid #e1e6eb;
			.box{
				    display: flex;
				    flex-direction: column;
				    height: 72px;
					.headerContent{
						padding-bottom: 8px;
					}
					.con{
						display: flex;
						    align-items: center;
						    justify-content: center;
						    width: 100%;
						    height: 100%;
						    color: #bbbec4;
						    font-size: 14px;
						    background: #f8f8f9;
					}
			}
	}
	.main{
		position: absolute;
		    bottom: 0;
		    left: 0;
			display: flex;
		    width: 100%;
		    height: 32px;
		    padding: 4px 16px;
		    background: #fff;
		    border-bottom-right-radius: 4px;
		    border-bottom-left-radius: 4px;
			span{
				flex: 1;text-align: right;
			}
	}
	.card_item2{
		.sub{
			.box{
				height: 120px;
			}
		}
		width: 300px;
		
	}
	.compamg_logo{
		    max-width: 20px!important;
		    max-height: 8px!important;
			margin-right: 10px;
			border-radius: none;
	}
</style>
